<template>
  <div class="loan_item">
    <div class="inner">
      <h3>Términos de privacidad</h3>
      <div class="left_cell">
        <div class="cell_item">
          <h2>Políticas de privacidad</h2>
          <p>Establecer los criterios para la recolección, almacenamiento, uso, circulación y supresión de los datos personales y Para estos efectos se dejará claramente establecido
            que los derechos de los titulares de la información que tengan el carácter de clientes o usuarios de One Conection , en desarrollo de una relación financiera o crediticia, se
            rigen por las disposiciones establecidas en la Ley Especial “1266 de 2008”. Así como, los Datos Personales de otros Titulares de la información que no tengan esas
            calidades se regirán por la Ley General “1581 de 2012.” Igualmente, los datos de clientes o usuarios cuando vayan a ser utilizados para fines de mercadeo o
            publicitarios serán tratados siguiendo las previsiones de la nueva Ley por Compañía Fintech de Colombia S.A.S - One Conection.</p>
        </div>
        <div class="cell_item">
          <h2>Definiciones:</h2>
          <p> ● Dato personal: Se trata de cualquier información vinculada o que pueda asociarse a una persona determinada, como su nombre o número de identificación, o que puedan hacerla determinable, como sus rasgos físicos. <br> ● Dato público: Es uno de los tipos de datos personales existentes. Son considerados datos públicos, entre otros, los datos relativos al estado civil de las personas, a su profesión u oficio y a su calidad de comerciante o de servidor público. Por su naturaleza, los datos públicos pueden estar contenidos, entre otros, en registros públicos, documentos públicos, gacetas y boletines oficiales y sentencias judiciales debidamente ejecutoriadas que no estén sometidas a reserva. <br> ● Dato semiprivado: Son los datos que no tienen naturaleza íntima, reservada, ni pública y cuyo conocimiento o divulgación puede interesar no solo al titular sino a cierto sector o a la sociedad en general. Los datos financieros y crediticios de la actividad comercial o de servicios, son algunos ejemplos.
            <br>  ● Dato privado: Es el dato que por su naturaleza íntima o reservada solo es relevante para el titular. Los gustos o preferencias de las personas, por ejemplo, corresponden a un dato privado.
            <br>  ● Datos sensibles: Son aquellos que afectan la intimidad del titular o pueden dar lugar a que lo discriminen, es decir, aquellos que revelan su origen racial o étnico, su orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos, así como los datos relativos a la salud, a la vida sexual, y los datos biométricos, entre otros.
            <br>  ● Autorización: Es el consentimiento que da cualquier persona para que las empresas o personas responsables del tratamiento de la información, puedan utilizar sus datos personales. Base de Datos Conjunto organizado de datos personales que son objeto de tratamiento.
            <br>  ● Encargado del tratamiento: Es la persona natural o jurídica que realiza el tratamiento de datos personales, a partir de una delegación que le hace el responsable, recibiendo instrucciones acerca de la forma en la que deberán ser administrados los datos.
            <br>  ● Responsable del tratamiento: Es la persona natural o jurídica, pública o privada, que decide sobre la finalidad de las bases de datos y/o el tratamiento de los mismos.
            <br>  ● Titular: Es la persona natural cuyos datos personales son objeto de tratamiento.
            <br>  ● Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.
            <br>  ● Aviso de privacidad: Es una de las opciones de comunicación verbal o escrita que brinda la ley para darle a conocer a los titulares de la información, la existencia y las formas de acceder a las políticas de tratamiento de la información y el objetivo de su recolección y uso.
            <br>  ● Transferencia: Se trata de la operación que realiza el responsable o el encargado del tratamiento de los datos personales, cuando envía la información a otro receptor, que, a su vez, se convierte en responsable del tratamiento de esos datos.</p>
        </div>
        <div class="cell_item">
          <h2>Alcance:</h2>
          <p>Esta política aplica para toda la información personal registrada en las bases de datos de One Conection quien actúa en calidad de responsable del tratamiento de los datos personales.</p>
        </div>
        <div class="cell_item">
          <h2>Obligaciones:</h2>
          <p>Esta política es de obligatorio y estricto cumplimiento para Compañía Fintech de Colombia S.A.S - One Conection RESPONSABLE DEL TRATAMIENTOCompañía Fintech de Colombia S.A.S - One Conection sociedad comercial legalmente constituida identificada con el NIT 901592017-2 con domicilio principal en CL 78 NO.84 28 AP 401,Bogotá D.C.República de Colombia. Página oneconection.co Teléfono 3125887294 en la ciudad de Bogota.</p>
        </div>
        <div class="cell_item">
          <h2>Tratamiento y finalidad:</h2>
          <p>Esta política es de obligatorio y estricto cumplimiento para Compañía Fintech de Colombia S.A.S - One Conection RESPONSABLE DEL TRATAMIENTOCompañía Fintech de Colombia S.A.S - One Conection sociedad comercial legalmente constituida identificada con el NIT 901592017-2 con domicilio principal El tratamiento que realizará One Conection con la información personal será el siguiente: Realizar ante los operadores de información (Datacrédito, Cifin y/o cualquier otra entidad que llegue a manejar bases de datos con los mismos objetivos) la consulta de información crediticia, así como el reporte de la generación, modificación, extinción, cumplimiento o incumplimiento de las obligaciones contraídas en favor de One Conection y la utilización indebida de los productos y/o servicios prestados u ofrecidos por la entidad.
            <br><br><br><br> Realizar todas las búsquedas y recopilación de información que nos permita realizar el estudio de capacidad crediticia y de pago incluyendo para el efecto la información reportada en planillas de seguridad social de acuerdo con la información presentada el empleador a las entidades administradoras de fondos de pensiones, ya sea directamente a través de terceros especialmente contratados para el efecto quienes podrán realizar la consulta correspondiente directamente ante las entidades recaudadoras o por medio de la información que consta en los Operadores de Información autorizados por el Ministerio de salud y Protección Social.en CL 78 NO.84 28 AP 401,Bogotá D.C.República de Colombia. Página One Conection-sas.co Teléfono 3187171958 en la ciudad de Neiva.
          </p>
          <br><br><br><br>
          <p>Compartir e intercambiar con sus entidades filiales, matrices, aliadas y/o con entidades financieras, información personal contenida en las bases de datos de la entidad con fines de control de riesgos, desembolso y pago de obligaciones, comerciales, estadísticos y la realización de actividades de mercadeo de sus servicios y publicidad.</p>
          <br><br><br><br>
          <p>Utilizar los comentarios, artículos, fotos, videos y otros contenidos que se publican en nuestra página de internet, o que se presentan por cualquier otro medio de comunicación de One Conection para la realización de actividades comerciales o de mercadeo de Compañía Fintech de Colombia S.A.S - One Conection a través de nuestra página de internet, Facebook y otros medios </p>
          <br><br><br><br>
          <p>de comunicación y utilizarlos como parte de nuestras campañas comerciales o de mercadeo dirigidas al público en general a través de distintos medios de comunicación.</p>
          <br><br><br><br>
          <p>Adelantar contactos con fines comerciales y promocionales ya sea sobre sus propios servicios y productos, o los de terceros con los que Compañía Fintech de Colombia S.A.S - One Conection tenga relaciones comerciales o alianzas, a través de correo, teléfono, celular, correo electrónico o cualquier otro medio conocido o por conocer.</p>
          <br><br><br><br>
          <p>Realizar actividades de gestión de cobro, aviso de reporte a las centrales de riesgo, entrega de extractos de obligaciones y actualizar tu información a través de diferentes actividades como lo son la consulta en bases de datos públicas, páginas de internet y redes sociales y referencias de terceras personas, en particular las personas que han servido de referencia para la utilización de los servicios de Compañía Fintech de Colombia S.A.S - One Conection</p>
          <br><br><br><br>
          <p>El tratamiento de la información podrá realizarse por Compañía Fintech de Colombia S.A.S - One Conection directamente o a través de terceros proveedores localizados en Colombia o en el exterior. Ese proceso puede implicar la recolección, archivo, procesamiento y transmisión de dicha información entre compañías vinculadas o relacionadas contractualmente con One Conection tales como proveedores del servicio de call y contact center, proveedores del servicio de mensajería, empresas de cobranza y profesionales del derecho que colaboran con la entidad la recuperación de su cartera, localizadas dentro o fuera de Colombia. En todo caso, esas entidades estarán igualmente sujetas a las mismas obligaciones de confidencialidad en el manejo de la información a que está sujeto la Compañía Fintech de Colombia S.A.S - One Conection con las limitaciones legales impuestas por las leyes aplicables sobre la materia, en la jurisdicción donde ésta se recolecte, archive, procese o transmita.</p>
          <br><br><br><br>
          <p>La información personal no será utilizada o tratada con propósitos diferentes a los aquí indicados.</p>
        </div>
        <div class="cell_item">
          <h2>Tratamiento de datos sensibles</h2>
          <p>Compañía Fintech de Colombia S.A.S - One Conection no almacena o trata datos que se puedan considerar sensibles y que no resulten adecuados para sus labores comerciales.</p>
        </div>
        <div class="cell_item">
          <h2>Derechos de los titulares</h2>
          <p>Como titular de sus datos personales Usted tiene derecho a:</p>
          <p>1. Acceder de forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento.</p>
          <p>2. Conocer, actualizar y rectificar su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté prohibido o no haya sido autorizado.</p>
          <p>3. Solicitar prueba de la autorización otorgada.</p>
          <p>4. Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente.</p>
          <p>5. Revocar la autorización y/o solicitar la supresión del dato, siempre que no exista un deber legal o contractual que impida eliminarlos.</p>
          <p>6. Abstenerse de responder las preguntas sobre datos sensibles. Tendrá carácter facultativo las respuestas que versen sobre datos sensibles o sobre datos de las niñas y niños y adolescentes.</p>
        </div>
        <div class="cell_item">
          <h2>Atención de peticiones, consultas y reclamos:</h2>
          <p>El área de Servicio Al Cliente es la dependencia que tiene a cargo dar trámite a las solicitudes de los titulares para hacer efectivos sus derechos. Nuestros Canales de Atencion Son:</p>
          <p>- Call Center: - Oficina: CL 78 no.84 28 AP401</p>
          <p>- Tel:3125887294</p>
          <p>- Pagina Web: One Conection-sas.co</p>
          <p>- Correo: info@One Conection-sas.co</p>
        </div>
        <div class="cell_item">
          <h2>Procedimientos para el ejercicio del derecho de habeas data:</h2>
          <p>En cumplimiento de las normas sobre protección de datos personales, Compañía Fintech de Colombia S.A.S - One Conection presenta el procedimiento y requisitos mínimos para el ejercicio de sus derechos:</p>
          <br><br><br><br>
          <p>Para la radicación y atención de su solicitud le solicitamos suministrar la siguiente información:</p>
          <br><br><br><br>
          <p>Nombre completo y apellidos, - Datos de contacto (Dirección física y/o electrónica y teléfonos de contacto), - Medios para recibir respuesta a su solicitud, - Motivo(s)/hecho(s) que dan lugar al reclamo con una breve descripción del derecho que desea ejercer (conocer, actualizar, rectificar, solicitar prueba de la autorización otorgada, revocarla, suprimir, acceder a la información) - Firma (si aplica) y número de identificación.</p>
          <br><br><br><br>
          <p>El término máximo previsto por la ley para resolver su reclamación es de quince (15) días hábiles, contado a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, Compañía Fintech de Colombia S.A.S - One Conection informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</p>
          <br><br><br><br>
          <p>Una vez cumplidos los términos señalados por la Ley 1581 de 2012 y las demás normas que la reglamenten o complementen, el Titular al que se deniegue, total o parcialmente, el ejercicio de los derechos de acceso, actualización, rectificación, supresión y revocación podrá poner su caso en conocimiento de la Superintendencia de Industria y Comercio -Delegatura para la Protección de Datos Personales.</p>
        </div>
        <div class="cell_item">
          <h2>Vigencia:</h2>
          <p>La presente Política para el Tratamiento de Datos Personales rige a partir del 01 de Agosto de 2019.</p>
          <br><br><br><br>
          <p>Las bases de datos en las que se registrarán los datos personales tendrán una vigencia igual al tiempo en que se mantenga y utilice la información para las finalidades descritas en esta política. Una vez se cumpla(n) esa(s) finalidad(es) y siempre que no exista un deber legal o contractual de conservar su información, sus datos serán eliminados de nuestras bases de datos.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loan_item{
  padding-bottom: 60px;
  .inner{
    min-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 18px;
      color: white;
      padding: 20px calc((100% - 1100px) / 2) !important;
      background: linear-gradient(270deg, #4DD093 0%, #1DAF99 100%);
    }
    .left_cell{
      padding: 0px calc((100% - 1100px) / 2 ) !important;
      .cell_item{
        h2{
          font-size: 16px;
          margin: 30px 0;
        }
        p{
          font-size: 14px;
          color: #999999;
          line-height: 25px;
        }
      }
    }
    .right_cell{
      float: left;
      width: 50%;
      // padding: 80px 0;
      img{
        width: 522px;
      }
    }
  }

}
@media only screen and (max-width: 520px){
  .loan_item{
    width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      h3{
        font-size: 16px !important;
        padding: 20px !important;
      }
      .left_cell{
        padding:  0 20px !important;
        .cell_item{
          h2{
            font-size: 14px !important;
          }
          p{
            font-size: 12px !important;
          }
        }
      }
    }
  }
}
</style>
