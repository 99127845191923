<template>
  <div class="loan_item">
    <div class="inner">
      <h3>LAFT</h3>
      <div class="left_cell">
        <div class="cell_item">
          <img src="@/assets/images/loanImg/icon_laft.png" alt="">
          <h3>POLÍTICA DE PREVENCIÓN DE LAVADO DE ACTIVOS Y FINANCIACIÓN DEL TERRORISMO </h3>
          <p>OneConection SAS en desarrollo de su actividad, implementa buenas prácticas, promoviendo e impulsando la cultura de administración del Riesgo, previniendo y controlando sus operaciones para evitar incurrir en delitos asociados al Lavado de Activos y Financiación del Terrorismo y así brindar una operación transparente.  <br><br> El análisis de riesgos para prevenir el lavado de activos, permite detectar operaciones sospechosas que puedan estar vinculadas con la canalización de recursos de origen delictual hacia la realización de actividades punibles o que busquen el ocultamiento de activos provenientes de actividades ilícitas, por lo que su observancia tiene carácter obligatorio. </p>
          <h4>1. Objeto:  </h4>
          <p>Definir y aplicar los lineamientos, controles y acciones necesarias para protegerse del riesgo de Lavado de Activos y Financiación del Terrorismo, comprometiendo a todas las contrapartes del OneConection SAS para tal efecto.  </p>
          <h4>2. Alcance: </h4>
          <p>Aplica al OneConection SAS, en lo relacionado con todos los procesos donde se presenten factores de riesgo de LA/FT, las contrapartes que involucran un factor de riesgo en este sentido y sobre las cuales se determinan los presentes lineamientos son: </p>
        </div>
        <div class="cell_item">
          <img src="@/assets/images/loanImg/icon_laft.png" alt="">
          <h3>POLÍTICA DE PREVENCIÓN DE LAVADO DE ACTIVOS Y FINANCIACIÓN DEL TERRORISMO </h3>
          <div class="tips">
            <div>
              <p>● Clientes</p>
              <p>● Manejo del efectivo</p>
            </div>
            <div>
              <p>● Proveedores</p>
              <p>● Otros terceros acreedores</p>
            </div>
            <div>
              <p>● Socios o accionistas </p>
              <p>● Agencias Vinculadas</p>
            </div>
            <div>
              <p>● Trabajadores de la empresa</p>
            </div>
          </div>
          <h4>3. Marco Legal: </h4>
          <p>Para dar cumplimiento a los convenios internacionales firmados por Colombia, en relación con la lucha contra las actividades ilícitas y el terrorismo, el gobierno ha venido sancionando normas que involucran a diferentes sectores de la economía, en la implementación de acciones tendientes a minimizar los riesgos y facilitar la detección de personas naturales o jurídicas, involucradas con estos delitos. </p>
          <h4>Normas y Estándares internacionales en Lavado de Activos y Financiación del Terrorismo y su adopción en Colombia: </h4>
          <img src="@/assets/images/loanImg/laft_bg.png" alt="">
        </div>
        <div class="cell_item">
          <img src="@/assets/images/loanImg/icon_laft.png" alt="">
          <h3>POLÍTICA DE PREVENCIÓN DE LAVADO DE ACTIVOS Y FINANCIACIÓN DEL TERRORISMO </h3>
          <img src="@/assets/images/loanImg/laft_bg2.png" alt="">
          <p>Estándares internacionales: Recomendaciones internacionales de Naciones Unidas, GAFI (Grupo de
            Acción Financiera Internacional), GAFIC (Grupo de Acción Financiera del Caribe), GAFISUD (Grupo de
            Acción Financiera de Sudamérica) </p>
          <div class="tips">
            <div>
              <p>● Estándar Australiano para la administración del Riesgo AS/NZS 4360. </p>
            </div>
            <div>
              <p>● Norma de Gestión de Riesgos ISO 31000:2009 </p>
            </div>
            <div>
              <p>● Modelo NRS (Negocios Responsables y Seguros) </p>
            </div>
            <div>
              <p>● Marco integrado de administración de riesgos corporativos </p>
            </div>
          </div>
          <img src="@/assets/images/loanImg/laft_bg3.png" alt="">
        </div>
        <div class="cell_item">
          <img src="@/assets/images/loanImg/icon_laft.png" alt="">
          <h3>POLÍTICA DE PREVENCIÓN DE LAVADO DE ACTIVOS Y FINANCIACIÓN DEL TERRORISMO  </h3>
          <img src="@/assets/images/loanImg/laft_bg4.png" alt="">
          <h3>4. Definiciones: </h3>
          <h3>En esta política se definen: </h3>
          <p>Las obligaciones de los colaboradores, como parte de la empresa y del sistema. </p>
          <p>Los principios de actuación de los miembros de la empresa frente al riesgo de Lavado de Activos y </p>
          <p>Financiación del Terrorismo. </p>
          <p>Los principios de relación con terceros </p>
          <p>Los principios de actuación frente a paraísos fiscales y jurisdicciones no cooperantes </p>
        </div>
        <div class="cell_item">
          <img src="@/assets/images/loanImg/icon_laft.png" alt="">
          <h3>POLÍTICA DE PREVENCIÓN DE LAVADO DE ACTIVOS Y FINANCIACIÓN DEL TERRORISMO   </h3>
          <h3>5. Políticas sobre prevención del riesgo de Lavado de Activos y Financiación del Terrorismo:  </h3>
          <p>Política 1. Colaborar en la lucha contra el Lavado de Activos y Financiación del Terrorismo, propendiendo al adecuado funcionamiento del Sistema de Prevención del Lavado de Activos. </p>
          <p>Política 2. Criterios de actuación de los colaboradores: La actuación de todos los miembros de la compañía frente a la prevención de Lavado de Activos y Financiación del Terrorismo, será siempre acorde con los principios y valores empresariales, los cuales se enmarcan dentro de los más altos estándares éticos. Como criterios específicos se definen los siguientes: </p>
          <div class="tips">
            <div>
              <p>● Conocimiento de los terceros relacionados: Todo trabajador del OneConection SAS, que intervenga en operaciones o transacciones de índole nacional o internacional, está obligado a aplicar las medidas de control que establezca la empresa tanto en esta política como en los procedimientos, para la adecuada y diligente identificación de terceros, en el marco de sus funciones </p>
            </div>
            <div>
              <p>● Colaboración para el logro de los objetivos: Todos los trabajadores del OneConection SAS, están obligados a colaborar en las verificaciones o recopilación de información requerida para el Sistema de Prevención en Lavado de Activos y Financiación del Terrorismo, así como a informar las operaciones que dentro del desarrollo normal de sus labores y lo definido en esta política corresponda a una operación inusual o sospechosa de Lavado de Activos o Financiación del Terrorismo. </p>
            </div>
            <div>
              <p>● Confidencialidad de la información: Ningún colaborador del OneConection SAS, podrá dar a conocer a terceros, información sobre procedimientos o controles en Lavado de Activos o Financiación del Terrorismo, así como los informes que sobre el tema se envíen a las autoridades competentes.  </p>
            </div>
          </div>
        </div>
        <div class="cell_item">
          <img src="@/assets/images/loanImg/icon_laft.png" alt="">
          <h3>POLÍTICA DE PREVENCIÓN DE LAVADO DE ACTIVOS Y FINANCIACIÓN DEL TERRORISMO    </h3>
          <p>conocer a terceros, información sobre procedimientos o controles en Lavado de Activos o Financiación del Terrorismo, así como los informes que sobre el tema se envíen a las autoridades competentes. </p>
          <p>Política 3. Principios de relación con Terceros Relacionados:
            OneConection SAS, realiza sus operaciones bajo los siguientes principios: </p>
          <div class="tips">
            <div>
              <p>● Cumplimos las leyes nacionales e internacionales vigentes, así como las normas y reglamentaciones que le aplican a la actividad y relacionados con el Lavado de Activos y la Financiación del Terrorismo.  </p>
            </div>
            <div>
              <p>● Cumplimos los principios fundamentales del pacto Global de las Naciones Unidas. </p>
            </div>
            <div>
              <p>● Cumplimos del Código de ética empresarial. </p>
            </div>
            <div>
              <p>● Actuamos con transparencia en la ejecución de las labores y en los resultados obtenidos en las actividades de las empresas. </p>
            </div>
            <div>
              <p>● Gestionamos el riesgo de forma responsable, razonable y sostenible.  </p>
            </div>
            <div>
              <p>● No transamos con vinculados en las listas ONU y OFAC.  </p>
            </div>
            <div>
              <p>● Reportamos a la UIAF las operaciones inusuales y sospechosas.   </p>
            </div>
          </div>
          <p>Política 4.  Analizar, revisar y reportar cuando corresponda los pagos recibidos desde paraísos fiscales según Decreto 1966 de 07 de octubre de 2014 y de jurisdicciones no cooperantes de acuerdo con el GAFI. </p>
          <p>La presente Política pertenece al OneConection SAS, la cual fue revisada y aprobada por la Junta Directiva el 01  de septiembre de 2022 mediante acta N.01.   </p>
        </div>
        <div class="cell_item">
          <img src="@/assets/images/loanImg/icon_laft.png" alt="">
          <h3>POLÍTICA DE PREVENCIÓN DE LAVADO DE ACTIVOS Y FINANCIACIÓN DEL TERRORISMO    </h3>
          <img src="@/assets/images/loanImg/laft_bg5.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loan_item{
  padding-bottom: 60px;
  .inner{
    min-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 18px;
      color: white;
      padding: 20px calc((100% - 1100px )/ 2) ;
      background: linear-gradient(270deg, #4DD093 0%, #1DAF99 100%);
    }
    .left_cell{
      padding: 80px 0;
      padding: 0px calc((100% - 1100px) / 2) 0;
      .cell_item{
        h2{
          font-size: 16px;
          margin: 30px 0;
        }
        h3{
          font-size: 14px;
          color: black;
          background: white;
        }
        h4{
          margin: 20px 0;
        }
        p{
          font-size: 14px;
          color: #999999;
          line-height: 25px;
        }
        img{
          width: 900px;
        }
        .tips{
          min-width: 440px;
          display: flex;
          flex-wrap: wrap;
          div{
            width: 100%;
            display: flex;
            p:nth-child(1){
              min-width: 230px;
            }
          }
        }
      }
    }
    .right_cell{
      float: left;
      width: 50%;
      // padding: 80px 0;
      img{
        width: 522px;
      }
    }
  }
}
@media only screen and (max-width: 520px){
  .loan_item{
    width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      h3{
        padding: 20px !important;
      }
      .left_cell{
        padding:  0 20px !important;
        .cell_item{
          img{
            width: 100%;
          }
          h2{
            font-size: 14px !important;
          }
          p{
            font-size: 12px !important;
          }
          .tips{
            min-width: 100% !important ;
            div{
              p{
                min-width: 50% !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
